$normal: #a8a878;
$fire: #f08030;
$water: #6890f0;
$grass: #78c850;
$electric: #f8d030;
$ice: #98d8d8;
$fighting: #c03028;
$poison: #a040a0;
$ground: #e0c068;
$flying: #a890f0;
$psychic: #f85888;
$bug: #a8b820;
$rock: #b8a038;
$ghost: #705898;
$dragon: #7038f8;
$dark: #705848;
$steel: #b8b8d0;
$fairy: #f0b6bc;
$reset: #333;

.pokemon-type, .inline-pokemon-type {
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  width: 90px;
  color: $white;
  text-shadow: 1px 1px 2px $black;
  padding: 4px;
  border-radius: 4px;
  border: 1px solid rgba(0,0,0,0.2);
  margin: 2px;
  &.normal {
    background-color: $normal;
  }
  &.fire {
    background-color: $fire;
  }
  &.water {
    background-color: $water;
  }
  &.grass {
    background-color: $grass;
  }
  &.electric {
    background-color: $electric;
  }
  &.ice {
    background-color: $ice;
  }
  &.fighting {
    background-color: $fighting;
  }
  &.poison {
    background-color: $poison;
  }
  &.ground {
    background-color: $ground;
  }
  &.flying {
    background-color: $flying;
  }
  &.psychic {
    background-color: $psychic;
  }
  &.bug {
    background-color: $bug;
  }
  &.rock {
    background-color: $rock;
  }
  &.ghost {
    background-color: $ghost;
  }
  &.dragon {
    background-color: $dragon;
  }
  &.dark {
    background-color: $dark;
  }
  &.steel {
    background-color: $steel;
  }
  &.fairy {
    background-color: $fairy;
  }
  &.reset, &.other {
    background-color: $reset;
  }
}
.inline-pokemon-type {
  width: auto;
  font-size: 12px;
  padding: 1px;
  margin: 1px 2px;
}