@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,400;0,700;1,400;1,700&display=swap');

@import "./colors";
@import "./pokemon_types";

@keyframes open {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}
html, body {
  height: 100%;
}

body {
  font-family: 'Nunito', sans-serif;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: $background;
  color: $primary-text;
  details {
    margin-top: 20px;
    summary {
      &::marker {
        color: $tertiary-accent;
      }
      &:hover {
        background-color: $background;
      }
      cursor: pointer;
      > * {
        display: inline;
      }
    }
  }
  details[open] summary ~ * {
    animation: open .5s ease-in-out;
  }
  span.searchResult {
    text-decoration: underline;
  }
  h1 {
    text-align: center;
    color: $primary-accent;
  }
  h2 {
    color: $secondary-accent;
  }
  h3 {
    color: $tertiary-accent;
  }
  a {
    color: $primary-link;
  }
  nav {
    ul {
      margin: 0px;
      padding: 0px;
      list-style-type: none;
      display: block;
      width: 100%;
      background-color: $navigation-background;
      text-align: center;
      box-shadow: 1px 1px 1px $black;
      li {
        display: inline-block;
        background-color: $navigation-button;
        text-transform: uppercase;
        padding: 5px 10px 5px 10px;
        margin: 5px;
        box-shadow: 1px 1px 2px $black;
        a {
          text-decoration: none;
        }
      }
    }
  }
  .app-container {
    background-color: $container-background;
    box-shadow: 2px 1px 5px $black;
    padding: 5px 10px;
    text-align: center;
    max-width: 800px;
    margin: 10px auto;
    border-radius: 4px;
    form {
      text-align: center;
      .searchBox {
        background-color: $navigation-background;
        display: inline-block;
        height: 30px;
        padding: 5px;
        margin: 0;
        border-radius: 8px;
        box-shadow: 2px 2px 1px $black;
        &:focus-within {
          -webkit-outline-radius: 8px;
          outline: 2px solid $navigation-button;
          outline-style: round;
          box-shadow: 4px 4px 2px $black;
        }
      }
      input[type="text"] {
        background-color: $navigation-background;
        color: $primary-text;
        border: 0;
        height: 30px;
        padding: 0px;
        margin-right: 32px;
        &:focus {
          border-color: inherit;
          -webkit-box-shadow: none;
          box-shadow: none;
          outline: none;
        }
      }
      .pokemon-search-field:not(:valid) ~ .closeIcon {
        display: none;
      }
      .closeIcon {
        border: 1px solid transparent;
        background-color: transparent;
        display: inline-block;
        vertical-align: middle;
        outline: 0;
        cursor: pointer;
        position: relative;
        top: -1px;
        right: -4px;
        z-index: 2;

        &:after {
          content: "X";
          display: block;
          width: 24px;
          height: 24px;
          background-color: $navigation-button;
          z-index: 1;
          right: 16px;
          top: 0;
          bottom: 0;
          margin-left: -8px;
          line-height: 22px;
          color: $primary-link;
          font-weight: bolder;
          font-size: 16px;
          cursor: pointer;
          border-radius: 3px;
        }
      }
    }
    ul.pokemon-list {
      list-style-type: none;
      display: block;
      margin: 10px;
      padding: 10px;
      li {
        display: flex;
        justify-content: space-between;
        text-transform: uppercase;
        width: 100%;
        text-align: left;
        padding: 0px 5px;
        border: 1px solid rgba(0,0,0,0.1);
        &:hover {
          cursor: pointer;
          background-color: rgba(0,0,0,0.2);
        }
        span.typeList {
          white-space: nowrap;
        }
        span.pokemonName {
          align-self: center;
        }
      }
    }
    .home {
      text-align: left;
      img {
        display: block;
        margin: auto;
        width: 80%;
        padding: 10px 0px;
      }
    }
  }
  .clickable {
    &:hover {
      cursor: pointer;
    }
  }
  .selected {
    border: 2px solid $primary-accent;
  }
  .hide {
    display: none !important;
  }
  #root {
    flex: 1 0 auto;
  }
  sup {
    color: $tertiary-accent;
  }
  footer {
    flex-shrink: 0;
    margin-top: 50px;
    background-color: $navigation-background;
    text-align: center;
    vertical-align: middle;
    height: 75px;
  }
  .about {
    text-align: left;
    h1 {
      text-align: center;
    }
  }
}

// Help prevent zooming on input focus on mobile
@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px;
  }
}