//$background: #1c3144;
$background: #000;
//$primary-text: #fcd0a1;
$primary-text: #bbb;

$container-background: rgba(255,255,255, 0.1);
// $primary-link: #9c92a3;
// 
// $primary-accent: #06d6a0;
// $secondary-accent: #1b9aaa;
// 
// $navigation-background: #333;
// $navigation-button: #555;
// 
// $shadow: #0b2033;
// 
// $white: #fff;
// $black: #000;

$primary-link: #ffce44;
$primary-accent: #fff;
$secondary-accent: #ccc;
$tertiary-accent: #a30;

$navigation-background: #333;
$navigation-button: #555;

$shadow: #0b2033;

$white: #fff;
$black: #000;